<template>
  <div class="affixion-contain">
    <Form :label-width="115">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" v-model="test_date" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" v-model="test_time" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="总胆固醇：">
            <Row>
              <Col :span="21"><Input v-model="cholesterinData.value" placeholder="请输入总胆固醇值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="甘油三脂：">
            <Row>
              <Col :span="21"><Input v-model="cholesterinData.trig" placeholder="请输入甘油三脂值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="高密度脂蛋白：">
            <Row>
              <Col :span="21"><Input v-model="cholesterinData.hdl" placeholder="请输入高密度脂蛋白值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="低密度脂蛋白：">
            <Row>
              <Col :span="21"><Input v-model="cholesterinData.ldl" placeholder="请输入低密度脂蛋白值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			cholesterinData: {
				test_date: '',
				test_time: '',
				value: '',
				trig: '',
				hdl: '',
				ldl: '',
			},
			test_date: '',
			test_time: '',
			power: [],
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		cholId() {
			return this.$route.params.cholId;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	methods: {
		handleInit() {
			let arr = [];
			MemberService.cholesterinDetail({ id: this.cholId }).then((data) => {
				arr = data.create_time.split(' ');
				this.test_date = this.cholesterinData.test_date = arr[0];
				this.test_time = this.cholesterinData.test_time = arr[1];
				this.cholesterinData.value = data.cholesterin === 0 ? '' : data.cholesterin;
				this.cholesterinData.trig = data.trig === 0 ? '' : data.trig;
				this.cholesterinData.hdl = data.hdl === 0 ? '' : data.hdl;
				this.cholesterinData.ldl = data.ldl === 0 ? '' : data.ldl;
			});
		},
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.cholesterinData.id = this.cholId;
				MemberService.cholesterinEdit(this.cholesterinData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.cholesterinData.test_date = value;
		},
		handleChangeTime(value) {
			this.cholesterinData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			} else if (!this.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			} else if (this.cholesterinData.value === '') {
				this.$Message.warning('请输入总胆固醇值');
				return false;
			} else if (!this.isPositiveNumber(this.cholesterinData.value)) {
				this.$Message.warning('请输入总胆固醇有效值');
				return false;
			} else if (!this.isPositiveNumber(this.cholesterinData.trig)) {
				this.$Message.warning('请输入甘油三脂有效值');
				return false;
			} else if (!this.isPositiveNumber(this.cholesterinData.hdl)) {
				this.$Message.warning('请输入高密度脂蛋白有效值');
				return false;
			} else if (!this.isPositiveNumber(this.cholesterinData.ldl)) {
				this.$Message.warning('请输入低密度脂蛋白有效值');
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
